import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";
import ModuleBase from "../../common/ModuleBase";
import { findFirst } from "obj-traverse/lib/obj-traverse";
import { objectDiff, sureClone } from "../../../lib/DataUtils";
import * as itemUtils from "@/lib/ItemUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:TemplatesStore`);

const DEFAULT_TEMPLATE_COLLECTION_EDITOR = {
  name: null,
  description: null,
};

const DEFAULT_CATEGORY_EDITOR = {
  name: null,
  information: null,
  printToPdfDefault: true,
};

const DEFAULT_CHECKLIST_EDITOR = {
  name: null,
  isCrossCheckList: false,
  isInformationOnly: false,
  printToPdfDefault: true,
};

const DEFAULT_CHECKLISTROW_EDITOR = {
  text: null,
  information: null,
  imageUri: null,
};

const DEFAULT_EDITOR = {
  id: null,
  parentId: null,
  restrictionMode: "Mandatory",
};

export default ModuleBase({
  state: {
    catalog: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    catalog({ catalog }) {
      let values = _.values(catalog);
      return values;
    },
    templateItemById:
      ({ catalog }) =>
      (templateId) => {
        let values = _.values(catalog);
        var first;
        for (let v of values) {
          first = findFirst(v, "children", { id: templateId });
          if (first) {
            break;
          }
        }
        return first;
      },
    templateItemByTargetId:
      ({ catalog }) =>
      (targetId) => {
        let values = _.values(catalog);
        var first;
        for (let v of values) {
          first = findFirst(v, "children", { targetId: targetId });
          if (first) {
            break;
          }
        }
        return first;
      },

    editorChanges(state, getters) {
      const itemId = _.get(state, "editor.id");
      if (!itemId) {
        return null;
      }
      const original = getters.templateItemById(itemId);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
  },

  mutations: {
    addTemplateItem(state, templateItem) {
      state.catalog[templateItem.id] = templateItem;
    },
    clearCatalog(state) {
      state.catalog = [];
    },
    deleteTemplateItem(state, templateItemId) {
      delete state.catalog[templateItemId];
    },
    newTemplateItemToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    newCategoryToTemplateItemEditor(state) {
      state.editor.category = sureClone(DEFAULT_CATEGORY_EDITOR);
    },
    newChecklistToTemplateItemEditor(state) {
      state.editor.checklist = sureClone(DEFAULT_CHECKLIST_EDITOR);
    },
    newChecklistRowToTemplateItemEditor(state) {
      state.editor.checklistRow = sureClone(DEFAULT_CHECKLISTROW_EDITOR);
    },
    newTemplateCollectionToTemplateItemEditor(state) {
      state.editor.templateCollection = sureClone(
        DEFAULT_TEMPLATE_COLLECTION_EDITOR,
      );
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let catalog = await ApiClient.get(`/templates`);
      debug("load", catalog);
      commit("set", ["catalog", _.keyBy(catalog, "targetId")]);
    },
    async loadRoot({ commit }) {
      debug("loadRoot");
      let catalog = await ApiClient.get(`/templates/root`);
      debug("loadRoot", catalog);
      commit("set", ["catalog", _.keyBy(catalog, "targetId")]);
    },
    async loadChildren({ commit, getters }, templateItemId) {
      debug("loadChildren");
      let response = await ApiClient.get(
        `/templates/${templateItemId}/children`,
      );
      debug("loadChildren", response);

      if (response.length > 0) {
        response.forEach((child) => {
          if (child.type === "CATEGORY") {
            if (child.targetItem != null) {
              commit("categories/addCategory", child.targetItem, {
                root: true,
              });
            }
          } else if (child.type === "CHECKLIST") {
            if (child.targetItem != null) {
              commit("checklists/addChecklist", child.targetItem, {
                root: true,
              });
            }
          } else if (child.type === "CHECKLISTROW") {
            if (child.targetItem != null) {
              commit("checklistRows/addChecklistRow", child.targetItem, {
                root: true,
              });
            }
          }
        });

        const parentItem = getters.templateItemById(response[0].parentId);
        parentItem.children = response;
      }
    },
    async moveUpTemplateItem(_, templateItemId) {
      debug("moveUpTemplateItem", templateItemId);
      let result = await ApiClient.post(
        `/templateItems/${templateItemId}/MoveUp`,
        templateItemId,
      );
      debug("moveUpTemplateItem, result", result);
    },
    async moveDownTemplateItem(_, templateItemId) {
      debug("moveDownTemplateItem", templateItemId);
      let result = await ApiClient.post(
        `/templateItems/${templateItemId}/MoveDown`,
        templateItemId,
      );
      debug("moveDownTemplateItem, result", result);
    },
    async createTemplateItem({ state, getters, commit }) {
      let templateItem = sureClone(state.editor);

      _.unset(templateItem, "id");
      debug("createTemplateItem", templateItem);
      let response = await ApiClient.post("/templateItems", templateItem);
      debug("createTemplateItem - response", response);

      const parentItem = getters.templateItemById(response.parentId);

      if (response.category != null) {
        commit("categories/addCategory", response.category, { root: true });

        var newItemCategory = {
          id: response.id,
          information: response.category.information ?? null,
          isFirstInSequence: false,
          isLastInSequence: true,
          name: response.category.name,
          parentId: response.parentId,
          restrictionMode: itemUtils.getRestrictionModeByNumber(
            response.restrictionMode,
          ),
          sequenceNumber: response.sequenceNumber,
          targetId: response.category.id,
          type: "CATEGORY",
          children: [],
        };

        debug("new checklist tree item to be added is ", newItemCategory);

        parentItem.children.push(newItemCategory);
      }
      if (response.checklist != null) {
        commit("checklists/addChecklist", response.checklist, { root: true });

        //TODO This could be returned from backend
        var newItemChecklist = {
          id: response.id,
          information: response.checklist.information ?? null,
          isFirstInSequence: false,
          isLastInSequence: true,
          name: response.checklist.name,
          parentId: response.parentId,
          restrictionMode: itemUtils.getRestrictionModeByNumber(
            response.restrictionMode,
          ),
          sequenceNumber: response.sequenceNumber,
          targetId: response.checklist.id,
          type: "CHECKLIST",
          children: [],
        };

        debug("new checklist tree item to be added is ", newItemChecklist);

        parentItem.children.push(newItemChecklist);
      }

      if (response.checklistRow != null) {
        commit("checklistRows/addChecklistRow", response.checklistRow, {
          root: true,
        });

        //TODO This could be returned from backend
        var newItemChecklistRow = {
          id: response.id,
          information: response.checklistRow.information ?? null,
          isFirstInSequence: false,
          isLastInSequence: true,
          name: response.checklistRow.name,
          parentId: response.parentId,
          restrictionMode: itemUtils.getRestrictionModeByNumber(
            response.restrictionMode,
          ),
          sequenceNumber: response.sequenceNumber,
          targetId: response.checklistRow.id,
          type: "CHECKLISTROW",
          children: [],
        };

        debug("new checklist tree item to be added is ", newItemChecklistRow);

        parentItem.children.push(newItemChecklistRow);
      }
      var previousItem = parentItem?.children?.find(
        (x) => x.sequenceNumber == response.sequenceNumber - 1,
      );
      if (previousItem != null) {
        previousItem.isLastInSequence = false;
      }
      return response;
    },
    async loadTemplateItemToEditor(
      { getters, commit, rootGetters },
      templateItemId,
    ) {
      debug("loadTemplateItemToEditor", templateItemId);
      const templateItem = getters.templateItemById(templateItemId);
      debug("templateItem", templateItem);
      if (!templateItem) {
        throw new Error("Template item not found");
      }

      commit("set", ["editor", sureClone(templateItem)]);

      if (templateItem.type === "CATEGORY") {
        var category = rootGetters["categories/categoryById"](
          templateItem.targetId,
        );
        commit("set", ["editor.category", sureClone(category)]);
      }

      if (templateItem.type === "CHECKLISTROW") {
        var checklistRow = rootGetters["checklistRows/checklistRowById"](
          templateItem.targetId,
        );
        commit("set", ["editor.checklistRow", sureClone(checklistRow)]);
      }
    },
    async copyTemplateItem(
      { commit },
      { templateItemId, targetTemplateItemId },
    ) {
      debug("copyTemplateItem", templateItemId);
      let response = await ApiClient.post(
        `/templateItems/${templateItemId}/clone?TargetId=${targetTemplateItemId}`,
      );
      debug("copyTemplateItem - response", response);
      commit("addTemplateItem", response);
    },
    async saveTemplateItemInEditor({ state, getters, commit }) {
      let templateItemId = state.editor.id;
      if (!getters.editorHasChanges) {
        debug(
          "saveTemplateItemInEditor Ei tallennettaa.",
          templateItemId,
          modifiedTemplateItem,
        );
        return;
      }
      let modifiedTemplateItem = sureClone(state.editor);
      debug("saveTemplateItemInEditor", templateItemId, modifiedTemplateItem);
      let result = await ApiClient.put(
        `/templateItems/${templateItemId}`,
        modifiedTemplateItem,
      );
      debug("saveTemplateItemInEditor, result", result);

      if (result) {
        commit("addTemplateItem", result);
      }
    },

    async syncChangesInTarget({ getters }, data) {
      const templateItem = getters.templateItemByTargetId(data.id);
      if (templateItem) {
        templateItem.name = data.name;
      }
    },
    async deleteTemplateItem({ commit }, templateItemId) {
      debug("deleteTemplateItem", templateItemId);
      let result = await ApiClient.delete(`/templateItems/${templateItemId}`);
      debug("deleteTemplateItem - result", result);
      commit("deleteTemplateItem", templateItemId);
      if (result?.categoryId != null) {
        commit("categories/deleteCategory", result.categoryId, { root: true });
      }
      if (result?.checklistId != null) {
        commit("checklists/deleteChecklist", result.checklistId, {
          root: true,
        });
      }
    },
  },
});
